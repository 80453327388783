import React, { useEffect } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Widget } from "@typeform/embed-react";

import classes from "./Submit.module.css";

const Submit = () => {
  const content = useLoaderData();

  const { setHeader } = useOutletContext();

  useEffect(() => {
    if (content) setHeader(content.page_header);
  }, [content, setHeader]);

  return (
    <div className={classes.Container}>
      {(content.header || content.content) && (
        <article className={classes.TextContainer}>
          <h3 className={classes.Header}>{content.header}</h3>
          <ReactMarkdown className={`markdown ${classes.Content}`}>
            {content.content}
          </ReactMarkdown>
        </article>
      )}

      {content.typeform_id && (
        <article className={classes.Form}>
          <Widget
            id={content.typeform_id}
            autoResize
            inlineOnMobile
            enableFullscreen
            disableScroll
            onHeightChanged={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
        </article>
      )}
    </div>
  );
};

export default Submit;
