import React from "react";
import ReactMarkdown from "react-markdown";
import { useLoaderData, useOutletContext } from "react-router-dom";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { CountUp } from "use-count-up";

import css from "./MarkContainer.module.css";
import "./common.css";

export const SharedSimple = () => {
  const { page_header, content } = useLoaderData();
  const { setHeader } = useOutletContext();
  const [isCounting, setIsCounting] = React.useState(true);

  React.useEffect(() => {
    if (page_header) setHeader(page_header);
    else setHeader("");
  }, [page_header, setHeader]);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      className={css.MarkContainer}
      linkTarget={"_blank"}
      children={content}
      components={{
        s(props) {
          const { children, className, node, value, ...rest } = props;

          return (
            <strong {...rest} className={className}>
              {children}
            </strong>
          );
        },
        data(props) {
          const { children, className, node, value, ...rest } = props;
          const { prefix, postfix } = props;
          console.log(props);
          const match = /number/.exec(className || "");

          return match && !isNaN(value) ? (
            !isCounting ? (
              <h1 {...rest} className={className}>
                {`${prefix || ""}${(+value).toLocaleString("en-GB", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}${postfix || ""}`}
              </h1>
            ) : (
              <h1 {...rest} className={className}>
                {prefix && prefix}
                <CountUp
                  isCounting={isCounting}
                  start={0}
                  end={+value}
                  onComplete={() => setIsCounting(false)}
                  duration={1.5}
                  easing={"linear"}
                  formatter={(value) => {
                    return (+value).toLocaleString("en-GB", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    });
                  }}
                />
                {postfix && postfix}
              </h1>
            )
          ) : (
            <data {...rest} className={className}>
              {children}
            </data>
          );
        },
      }}
    />
    // >
    //   {content}
    // </ReactMarkdown>
  );
};

export default SharedSimple;
