import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { useCookies } from "react-cookie";

import ConsentItem from "./ConsentItem";

import {
  cookieFunctions,
  initializeGA,
  COOKIE_PROPS,
  COOKIE_TYPE,
} from "../../../util/lib";

import classes from "./ConsentModal.module.css";

const ConsentModal = (props) => {
  const { data } = props;
  const [hideModal, setHideModal] = useState(false);

  const [cookies, setCookies, removeCookie] = useCookies([
    COOKIE_TYPE.ANALYTICS_GA,
    COOKIE_TYPE.ANALYTICS_GID,
    COOKIE_TYPE.CONSENT,
  ]);
  const consentObjRef = useRef();

  const [showDetail, setShowDetail] = useState(false);

  const closeModal = () => setHideModal(true);

  const acceptHandler = () => {
    const consentObj = { [COOKIE_TYPE.GA]: 1 };
    setCookies(COOKIE_TYPE.CONSENT, consentObj, COOKIE_PROPS);
    initializeGA();
    closeModal();
  };

  const manageHandler = () => setShowDetail(true);

  const saveHandler = () => {
    data.consent_item.map((item) => {
      cookieFunctions(
        item.type,
        consentObjRef.current[item.type]?.selected,
        removeCookie,
        setCookies
      );
      return item;
    });

    closeModal();
  };

  const changeConsentHandler = (type, isChecked) => {
    consentObjRef.current[type].selected = isChecked;
  };

  useEffect(() => {
    if (!data) return;

    consentObjRef.current = {};

    data?.consent_item.map((item) => {
      consentObjRef.current[item.type] = item;
      return item;
    });
  }, [data]);

  useEffect(() => {
    const storedConsent = cookies[COOKIE_TYPE.CONSENT]; //consent object
    console.log("storedConsent: ", COOKIE_TYPE.CONSENT, storedConsent);

    if (storedConsent) {
      setHideModal(true);
      if (storedConsent[COOKIE_TYPE.GA] === 1) initializeGA();
    }
  }, []);

  if (!data) return null;

  const renderItems = (items) => {
    return items.map((item) => (
      <article key={item.id}>
        <div className={classes.ItemContainer}>
          <h1 className={classes.Header}>{item.title}</h1>
          <ConsentItem
            id={item.id}
            type={item.type}
            selected={item.selected}
            onChange={changeConsentHandler}
          >
            {item.title}
          </ConsentItem>
        </div>

        <ReactMarkdown className={classes.Content}>
          {item.content}
        </ReactMarkdown>
      </article>
    ));
  };

  const detailSection = (
    <section>
      {renderItems(data.consent_item)}
      <button onClick={saveHandler} className={classes.Accept}>
        {data.button_save}
      </button>
    </section>
  );

  const mainSection = (
    <section>
      <h1 className={classes.Header}>{data.title}</h1>
      <ReactMarkdown className={classes.Content}>{data.content}</ReactMarkdown>
      <div className={classes.ActionContainer}>
        {data.button_reject && (
          <button onClick={manageHandler} className={classes.Reject}>
            {data.button_reject}
          </button>
        )}
        {data.button_accept && (
          <button onClick={acceptHandler} className={classes.Accept}>
            {data.button_accept}
          </button>
        )}
      </div>
    </section>
  );

  return (
    <div
      className={`${classes.Container} ${
        hideModal ? classes.Hide : classes.Show
      }`}
    >
      {showDetail ? detailSection : mainSection}
    </div>
  );
};

export default ConsentModal;
