import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import classes from "./Mardown.module.css";

const Markdown = (props) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={`${classes.Container} ${props.className}`}
    >
      {props.children}
    </ReactMarkdown>
  );
};

export default Markdown;
