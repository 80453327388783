import React, { useEffect } from "react";
import { Outlet, useLoaderData, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

import Navbar from "./Navbar/Desktop/Navbar";
import MobileNavbar from "./Navbar/Mobile/MobileNavbar";

import classes from "./AppLayout.module.css";
import Footer from "./Footer/Footer";
import ConsentModal from "../ConsentModal/ConsentModal";

const AppLayout = () => {
  const content = useLoaderData();
  const { pathname, hash } = useLocation();

  const filteredNavbar = content.navbar
    ? content.navbar.filter((el) => el.isActive)
    : [];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    console.log("new page view: ", pathname);
    ReactGA.pageview(pathname);
    TagManager.dataLayer({
      dataLayer: {
        event: "Pageview",
        page: pathname,
      },
    });
    if (window.hubSpot && window.hubSpot.push) {
      console.log("hubSpot path:", pathname);
      window.hubSpot.push(["setPath", pathname]);
      window.hubSpot.push(["trackPageView"]);
    }
  }, [pathname]);

  useEffect(() => {
    const element = document.getElementById(hash.replace("#", ""));
    if (hash && element) {
      element.scrollIntoView({
        behavior: "smooth",
      });

      console.log("new page view: ", pathname + hash);
      ReactGA.pageview(pathname);
      TagManager.dataLayer({
        dataLayer: {
          event: "Pageview",
          page: pathname + hash,
        },
      });

      if (window.hubSpot && window.hubSpot.push) {
        console.log("hubSpot path:", pathname + hash);
        window.hubSpot.push(["setPath", pathname + hash]);
        window.hubSpot.push(["trackPageView"]);
      }
    }
  }, [hash]);

  return (
    <>
      <Navbar
        className={classes.Desktop}
        data={filteredNavbar}
        content={content.company}
      />
      <MobileNavbar
        className={classes.Mobile}
        data={filteredNavbar}
        content={content.company}
      />
      <main className={classes.Content}>
        <Outlet />
      </main>
      <Footer data={content.footer} />
      <ConsentModal data={content?.consent || null} />
    </>
  );
};

export default AppLayout;
