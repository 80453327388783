import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";

import classes from "./Form.module.css";
import CheckIcon from "../../../../../../assets/CheckIcon";

const initialState = { value: "", isTouched: false };

const ContactForm = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { subscribe, status, message } = props; //mailchimp props
  const { button, text, header } = props; //api props
  // status: "sending", "error", "success"
  // messages are customized here

  const formRef = useRef();

  const [name, setName] = useState(initialState);
  const [surname, setSurname] = useState(initialState);
  const [email, setEmail] = useState(initialState);
  const [isConsented, setIsConsented] = useState(!text); //if no consent text, it's consented

  const [validationMessage, setValidationMessage] = useState("");

  const isValid = {
    name: !!name.value.trim(),
    surname: !!surname.value.trim(),
    email: /^\S+@\S+\.\S+$/.test(email.value),
  };

  const isFormvalid =
    isValid.name && isValid.email && isValid.surname && isConsented;

  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (isFormvalid) {
      subscribe({
        NAME: `${name.value.trim()} ${surname.value.trim()}`,
        EMAIL: email.value.trim(),
      });
    }
  };

  const setTag = async (userEmail) => {
    const response = await axios.post("/api/updateTag", {
      email: userEmail,
    });
    const { message, error } = response.data;
    console.log(message, error);
  };

  const resetForm = () => {
    setName(initialState);
    setSurname(initialState);
    setEmail(initialState);
    if (!!text) setIsConsented(false);
    setValidationMessage("");
  };

  useEffect(() => {
    if (status === "success") {
      setTag(email.value.trim()).finally(() => {
        resetForm();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const formClasses = [
    classes.Form,
    isFormvalid ? classes.Valid : classes.Invalid,
  ].join(" ");

  const changeHandler = (val, set) => {
    set({
      value: val,
      isTouched: true,
    });
  };

  return (
    <form ref={formRef} className={formClasses} onSubmit={formSubmitHandler}>
      {!!header && (
        <ReactMarkdown className={`${classes.ConsentHeader} markdown`}>
          {header}
        </ReactMarkdown>
      )}
      <input
        className={`${classes.Name} ${
          name.isTouched
            ? !isValid.name
              ? classes.Invalid
              : classes.Valid
            : ""
        }`}
        value={name.value}
        onChange={(e) => changeHandler(e.target.value, setName)}
        placeholder="First Name"
      />
      <input
        className={`${classes.Surname} ${
          surname.isTouched
            ? !isValid.surname
              ? classes.Invalid
              : classes.Valid
            : ""
        }`}
        value={surname.value}
        onChange={(e) => changeHandler(e.target.value, setSurname)}
        placeholder="Last Name"
      />
      <input
        className={`${classes.Email} ${
          email.isTouched
            ? !isValid.email
              ? classes.Invalid
              : classes.Valid
            : ""
        }`}
        type="email"
        value={email.value}
        onChange={(e) => changeHandler(e.target.value, setEmail)}
        placeholder="E-Mail"
      />
      {!!text && (
        <div
          className={classes.Consent}
          onClick={() => setIsConsented((prev) => !prev)}
        >
          <CheckIcon isChecked={isConsented} />
          <ReactMarkdown className={`${classes.ConsentText} markdown`}>
            {text}
          </ReactMarkdown>
        </div>
      )}
      <button
        className={classes.Button}
        disabled={status === "sending"}
        type="submit"
        title={
          isFormvalid ? "" : "Please provide all the information requested."
        }
      >
        {button}
      </button>

      <p
        className={classes.Message}
        style={{ color: status === "success" ? "#b0ffb6" : "#ff3535" }}
      >
        {validationMessage
          ? validationMessage
          : status === "success"
          ? "Thank you for subscribing!"
          : status === "error"
          ? "There was an error. Please check your information or try again later!"
          : ""}
      </p>
    </form>
  );
};

export default ContactForm;
